/* Bootstrap */

// Functions first
@import "../../node_modules/bootstrap/scss/functions";

// Default variable overrides
$body-bg: #fff;
$body-color: #000;

// Required Bootstrap imports
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";

// Optional components
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";

// @import "../../node_modules/bootstrap/scss/dropdown";
// @import "../../node_modules/bootstrap/scss/buttons";

@import "../../node_modules/bootstrap/scss/helpers";
// @import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/utilities";



@import "../../node_modules/bootstrap/scss/utilities/api";